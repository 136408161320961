<template>
  <div>
    <div class="title">
      {{ details["title"] }}
    </div>
    <!-- {{ details }} -->
    <!-- <hr /> -->
    <Bar :chart-options="chartOptions" :chart-data="chartData" />
  </div>
</template>

<script>
const _ = require("lodash");
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Legend,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  props: ["details"],
  components: { Bar },
  computed: {
    chartOptions() {
      return {
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
        },
      };
    },

    datasets() {
      let datasets = [];

      _.each(this.details["axes"], (axis) => {
        datasets.push(_.map(axis.values, "key"));
      });

      return _.without(_.uniq(_.flatten(datasets)), undefined);
    },

    chartData() {
      return {
        labels: _.map(this.details["axes"], "name"),
        datasets: _.map(this.datasets, (dataset, i) => {
          let values = [];
          _.each(this.details["axes"], (axis) => {
            let vals = axis["values"];
            let value = _.find(vals, { key: dataset });
            values.push(value ? value.value : null);
          });

          return {
            label: dataset,
            data: values,
            backgroundColor: this.getBackgroundColor(i),
          };
        }),
        // datasets: [
        //   {
        //     label: "Data One",
        //     backgroundColor: "#f87979",
        //     data: _.map(this.details['values']),
        //   },
        // ],
      };
    },
  },
  data() {
    return {};
  },
  methods: {
    getBackgroundColor(i) {
      switch (i) {
        case 0:
          return "#f87979";
        case 1:
          return "#2f4b7c";
        case 2:
          return "#665191";
        case 3:
          return "#a05195";
        default:
          return "#ffa600";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: 500;
  margin-bottom: 15px;
  // text-align: center;
}
</style>