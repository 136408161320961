<template>
  <div>
    <!-- Topbar -->
    <div class="topbar">
      <div class="super-left">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="goBack"
          icon="left"
        ></a-button>
      </div>
      <div class="left">
        <div class="shortcuts">
          <a-button class="shortcut-button" @click.prevent="todayShortcut"
            >Today</a-button
          >
          <a-button class="shortcut-button" @click.prevent="yesterdayShortcut"
            >Yesterday</a-button
          >
          <a-button class="shortcut-button" @click.prevent="thisWeekShortcut"
            >This Week</a-button
          >

          <a-button class="shortcut-button" @click.prevent="lastWeekShortcut"
            >Last Week</a-button
          >
        </div>
        <div class="date-range">
          <a-date-picker v-model="start_date" />
          <span class="separator">-</span>
          <a-date-picker v-model="end_date" />
        </div>
      </div>
      <div class="right">
        <a-select v-model="selected_organisation" style="width: 250px">
          <a-select-option
            v-for="org in organisationsIAmAnAdminForOrdered"
            :value="org.id"
            :key="org.id"
          >
            {{ org.displayName }}
          </a-select-option>
        </a-select>

        <a-button
          type="primary"
          @click.prevent="fetch"
          icon="reload"
          style="margin-left: 15px"
          >Reload</a-button
        >
      </div>
    </div>
    <!-- / Topbar -->

    <!-- Loading -->
    <div class="loader" v-if="isLoading">
      <a-spin></a-spin>
    </div>
    <!-- / Loading -->

    <!-- Metrics -->
    <div v-if="!isLoading" class="metrics-wrapper">
      <a-row :gutter="20" type="flex">
        <a-col
          class="metric-col"
          v-for="(metric, i) in metrics"
          :key="i"
          :span="metricColSpan"
        >
          <metric :metric="metric"></metric>
        </a-col>
      </a-row>
    </div>
    <!-- / Metrics -->

    <!-- No Stats -->
    <a-alert
      v-if="!isLoading && !stats.length"
      type="info"
      message="No stats to show"
    />
    <!-- / No Stats -->

    <!-- Stats -->
    <div v-if="!isLoading" class="stats-wrapper">
      <a-row :gutter="20" type="flex">
        <a-col
          class="stat-col"
          v-for="(stat, i) in stats"
          :key="i"
          :span="colSpan"
        >
          <div class="stat-wrapper">
            <stat :stat="stat"></stat>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- / Stats -->
  </div>
</template>

<script>
const _ = require("lodash");
import { mapGetters, mapActions } from "vuex";
import Metric from "./Stats/Metric.vue";
import Stat from "./Stats/Stat.vue";
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  created() {
    // this.setStartDate(window.moment());
    // this.setEndDate(window.moment());
    this.reset();
    this.fetch();
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  components: { Stat, Metric },

  methods: {
    ...mapActions("admin", {
      selectOrganisation: "selectOrganisation",
    }),

    ...mapActions("adminStats", {
      setStartDate: "setStartDate",
      setEndDate: "setEndDate",
      fetch: "fetch",
      reset: "reset"
    }),

    goBack() {
      this.$router.push('/admin');
    },

    todayShortcut() {
      this.setStartDate(window.moment());
      this.setEndDate(window.moment());
    },

    yesterdayShortcut() {
      let yesterday = window.moment().subtract(1, "day");
      this.setStartDate(yesterday);
      this.setEndDate(yesterday);
    },

    thisWeekShortcut() {
      let beginningOfWeek = window.moment().startOf("week");
      let endOfWeek = window.moment().endOf("week");
      this.setStartDate(beginningOfWeek);
      this.setEndDate(endOfWeek);
    },

    lastWeekShortcut() {
      let beginningOfWeek = window.moment().startOf("week");
      let endOfWeek = window.moment().endOf("week");
      this.setStartDate(beginningOfWeek.subtract(1, "week"));
      this.setEndDate(endOfWeek.subtract(1, "week"));
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      organisationsIAmAnAdminFor: "organisationsIAmAnAdminFor",
    }),

    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
    }),

    ...mapGetters("adminStats", {
      startDate: "startDate",
      endDate: "endDate",
      stats: "stats",
      metrics: "metrics",
      isLoading: "isLoading",
    }),

    organisationsIAmAnAdminForOrdered() {
      return _.orderBy(
        this.organisationsIAmAnAdminFor,
        ["displayName"],
        ["asc"]
      );
    },

    metricColSpan() {
      if (this.windowWidth > 1200) {
        return 6;
      }
      if (this.windowWidth > 800) {
        return 8;
      }
      if (this.windowWidth > 500) {
        return 12;
      }
      return 24;
    },

    colSpan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      return 24;
    },

    start_date: {
      get() {
        return this.startDate;
      },
      set(val) {
        this.setStartDate(val);
      },
    },

    end_date: {
      get() {
        return this.endDate;
      },
      set(val) {
        this.setEndDate(val);
      },
    },

    selected_organisation: {
      get() {
        return this.selectedOrganisation ? this.selectedOrganisation.id : null;
      },
      set(val) {
        this.selectOrganisation(
          _.find(this.organisationsIAmAnAdminFor, { id: val })
        );
        this.fetch();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.topbar {
  display: flex;
  margin-bottom: 30px;

  background: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;

    .date-range {
      display: inline-block;
      .separator {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .shortcuts {
      display: flex;
      align-items: center;

      margin-right: 20px;

      .shortcut-button {
        margin-right: 7px;
      }
    }
  }

  .right {
    flex-shrink: 1;
  }
}

// Metrics
.metrics-wrapper {
  padding-bottom: 10px;
}

.metric-col {
  margin-bottom: 20px;
}

// Stats
.stats-wrapper {
  padding-bottom: 40px;
}

.stat-col {
  margin-bottom: 20px;
}

.stat-wrapper {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  height: 100%;
}

.loader {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;
  text-align: center;
}
</style>