<template>
  <div class="metric-card">
    <a-statistic :title="title" :value="value" />
    <div class="byline">
      {{ metric.byline }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["metric"],
  computed: {
    title() {
      let t = this.metric.name;
      if (this.metric.unit) {
        t = t + ' (' + this.metric.unit + ')'
      }
      return t;
    },

    value() {
      let v = this.metric.value;
      if (!v) {
        return "n/a";
      }
      if (this.metric.unit) {
        v = v + this.metric.unit;
      }
      return v; 
    }
  }
};
</script>

<style scoped lang="scss">
.metric-card {
  background: #fff;
  padding: 13px;
  border-radius: 6px;
  height: 100%;

  .byline {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>