<template>
  <div>
    <vertical-bar-chart 
    v-if="type == 'Soteria.Models.StatisticVerticalBarChartDetails, Soteria.Models'"
    :details="details"></vertical-bar-chart>
  </div>
</template>

<script>
import VerticalBarChart from "./Stat/VerticalBarChart.vue";
export default {
  props: ["stat"],
  components: { VerticalBarChart },
  computed: {
      type() {
          return this.stat['$t']
      },
      details() {
          return this.stat['$v']
      }
  }
};
</script>

<style>
</style>